import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Layout from "../components/layout";

const AboutPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
						company
					}
				}
			}
		`
	);

	const companyName = data.site.siteMetadata.company;
	const title = `Thank you for contacting ${companyName}`;

	if (typeof window !== "undefined") {
		return (
			<Layout>
				<GatsbySeo title={title} language="en" noindex />
				<section>
					<Container>
						<Row className="py-5">
							<Col>
								<h1 className="pb-3">Thank you for contacting {companyName}</h1>
								<p>We will get back to as soon as possible</p>
								<Link className="link-orange" to="/">
									<p> &#8592; Home</p>
								</Link>
							</Col>
						</Row>
					</Container>
				</section>
			</Layout>
		);
	}
};
export default AboutPage;
